import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import HugggMap from '@huggg/maps-browser';
import MapSearch from './MapSearch';
import Loader from './Loader';
import classes from './BrandMap.module.css';

const MAX_ZOOM = 15;
const MIN_ZOOM = 9;
const DEFAULT_ZOOM_ON_PAN = 14;

class BrandMap extends Component {
  static propTypes = {
    google: PropTypes.object,
    locations: PropTypes.arrayOf(PropTypes.object)
  };

  constructor(props) {
    super(props);
    this.state = { hugggMap: null };
    this.mapRef = createRef();
  }

  panTo = ({ coords = null, bounds = null }) => {
    if (!this.state.hugggMap || !coords) {
      return;
    }

    this.state.hugggMap.panToPosition(coords);
    if (bounds) {
      this.state.hugggMap.fitBounds(bounds);
    }

    const currentZoom = this.state.hugggMap.getZoom();
    if (currentZoom > MAX_ZOOM || currentZoom < MIN_ZOOM) {
      this.state.hugggMap.setZoom(DEFAULT_ZOOM_ON_PAN);
    }
  };

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
    if (prevProps.locations !== this.props.locations) {
      this.updateLocations();
    }
  }

  updateLocations = () => {
    if (this.state.hugggMap && this.props.locations) {
      this.state.hugggMap.updatePurchaseLocationMarkers(this.props.locations);
    }
  };

  loadMap = () => {
    if (this.props.google && this.props.google.maps) {
      const { current: mapEl } = this.mapRef;
      HugggMap.init(this.props.google.maps, mapEl)
        .then(hugggMap => {
          this.setState({ hugggMap });
        })
        .then(this.updateLocations) // if the locations loaded in before google maps did, we need to make sure they're on the map!
        .catch(err => console.log(err));
    }
  };

  subscribeToInteractions = subscriber => {
    this.state.hugggMap.addListener('click', subscriber);
    this.state.hugggMap.addListener('bounds_changed', subscriber);
  };

  render() {
    return (
      <div className={classes.brandMap}>
        {this.state.hugggMap ? (
          <div className={classes.search}>
            <MapSearch
              google={this.props.google}
              panTo={this.panTo}
              mapInteraction={this.subscribeToInteractions}
            />
          </div>
        ) : (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        <div ref={this.mapRef} className={classes.mapLayer} />
      </div>
    );
  }
}

export default BrandMap;
