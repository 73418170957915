/**
 * Splits a query string. Typically used as queryParams(window.location.search);
 *
 * @param {string} queryString
 */
export const queryParams = queryString => {
  const params = {};
  const query = queryString.substring(1);
  query.split('&').forEach(param => {
    const pair = param.split('=');
    params[pair[0]] = pair[1];
  });
  return params;
};
