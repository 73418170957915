/**
 * MadeByHuggg Component
 *
 * A small stateless UI component which displays the "made with love by
 * huggg" strapline, with "huggg" as a logo. It will use your theme's
 * secondary text color.
 *
 */

import React from 'react';
import logo from '../logo.png';
import classes from './MadeByHuggg.module.css';

const MadeByHuggg = () => (
  <span className={classes.text}>
    made with love by
    <img className={classes.img} src={logo} alt="huggg" />
  </span>
);

export default MadeByHuggg;
