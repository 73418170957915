import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HugggMapSearch } from '@huggg/maps-browser';
import classes from './MapSearch.module.css';
import cross from '../cross.svg';
import { utils } from '@huggg/back-bar';

const MIN_SEARCH_LENGTH = 3;
const MAX_RESULTS = 5;

const validQuery = query => query && query.length >= MIN_SEARCH_LENGTH;

const isKey = (id, label) => e =>
  e.keyCode === id || e.key === label || e.which === id;

const isEnterKey = isKey(13, 'Enter');
const isDownKey = isKey(40, 'ArrowDown');
const isUpKey = isKey(38, 'ArrowUp');

const ahead = n => n + 1;
const back = n => n - 1;

class MapSearch extends Component {
  static propTypes = {
    google: PropTypes.object,
    panTo: PropTypes.func,
    mapInteraction: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      results: [],
      activeResult: null,
      searchInstance: null
    };

    const cachedSearch = new utils.ExpiringCache(query =>
      this.state.searchInstance.search(query)
    );
    this.cachedSearch = cachedSearch.get.bind(cachedSearch);

    this.inputDidChange = this.inputDidChange.bind(this);
    this.inputKeyUp = this.inputKeyUp.bind(this);
    this.listKeyUp = this.listKeyUp.bind(this);
    this.clearSearchResults = this.clearSearchResults.bind(this);
  }

  clearSearchResults() {
    this.setState({ results: [], activeResult: null });
  }

  loadSearch() {
    if (!this.props.google.maps) {
      return;
    }
    const searchInstance = new HugggMapSearch(this.props.google.maps);
    this.setState({ searchInstance });
    this.props.mapInteraction(this.clearSearchResults);
  }

  doSearch(query) {
    return this.cachedSearch(query)
      .then(results => results.slice(0, MAX_RESULTS))
      .then(results => this.setState({ results }))
      .catch(() => this.clearSearchResults());
  }

  navigateListing(modifier) {
    // don't push null through the modifier or we'll get NaN!
    const newValue =
      this.state.activeResult === null ? 0 : modifier(this.state.activeResult);

    if (!this.state.results.length || newValue < 0) {
      this.setState({ activeResult: null });
    } else {
      this.setState({
        activeResult: Math.min(this.state.results.length - 1, newValue)
      });
    }
  }

  inputDidChange(e) {
    const query = e.target.value;
    if (this.state.searchInstance && validQuery(query)) {
      this.doSearch(query);
    } else {
      this.clearSearchResults();
    }
  }

  inputKeyUp(e) {
    if (isEnterKey(e) && e.target.value.length > 0) {
      this.doSearch(e.target.value);
    } else if (isDownKey(e)) {
      this.navigateListing(ahead);
    }
  }

  listKeyUp(e) {
    if (isUpKey(e)) {
      this.navigateListing(back);
    } else if (isDownKey(e)) {
      this.navigateListing(ahead);
    }
  }

  resultSelected(result) {
    this.state.searchInstance
      .getResultCoordinates(result)
      .then(this.props.panTo);
    this.clearSearchResults();
  }

  componentDidMount() {
    this.loadSearch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.google !== this.props.google) {
      this.loadSearch();
    }
  }

  render() {
    return (
      <div>
        <input
          type="text"
          disabled={!this.state.searchInstance}
          className={classes.input}
          onChange={this.inputDidChange}
          onKeyUp={this.inputKeyUp}
          placeholder="Search for a place.."
          ref={input =>
            input && this.state.activeResult === null && input.focus()
          }
        />

        {this.state.results.length ? (
          <>
            <button
              className={classes.closeButton}
              onClick={this.clearSearchResults}
            >
              <img src={cross} alt="Cross icon to close results" />
            </button>
            <ul className={classes.resultList} onKeyUp={this.listKeyUp}>
              {this.state.results.map((result, idx) => {
                return (
                  <li className={classes.resultItem} key={idx}>
                    <button
                      onClick={() => this.resultSelected(result)}
                      ref={button =>
                        button &&
                        this.state.activeResult === idx &&
                        button.focus()
                      }
                      className={classes.resultItemLink}
                    >
                      {result.description}
                    </button>
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}
      </div>
    );
  }
}

export default MapSearch;
