import React, { Component } from 'react';
import MadeByHuggg from './Components/MadeByHuggg.js';
import BrandMap from './Components/BrandMap.js';
import { brands, locations } from '@huggg/back-bar';
import { getPredefinedBounds } from '@huggg/maps-browser';
import config from './Config';
import PropTypes from 'prop-types';

import './App.css';

const UK_BOUNDS = getPredefinedBounds('GB');

const getLocations = brandId => {
  return brandId
    ? brands
      .byId([brandId], ['stores'])
      .then(res => res && res.embedded && res.embedded.stores)
    : locations
      .locationsWithin(
        UK_BOUNDS.north,
        UK_BOUNDS.south,
        UK_BOUNDS.east,
        UK_BOUNDS.west
      )
      .catch(err => console.log(err));
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { google: null, locations: [] };
  }

  // hideous wrangling to bootstrap Maps components once google maps
  // are available AND NOT A MINUTE SOONER.
  insertGoogleMaps() {
    return new Promise((resolve, reject) => {
      if (typeof window.google !== 'undefined') {
        resolve(window.google);
        return;
      }
      // UH OH! Global! The alternative is an onload handler for the script which
      // then polls window for google to be ready... neither are good. I think
      // this is least-worst.
      window.huggg__mapsLoaded = () => resolve(window.google);

      const script = document.createElement('script');
      script.src = `//maps.googleapis.com/maps/api/js?key=${
        config.GOOGLE_MAPS_API_KEY
      }&libraries=places,geometry&callback=huggg__mapsLoaded`;
      script.async = true;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  componentDidMount() {
    this.insertGoogleMaps()
      .then(google => {
        this.setState({ google });
      })
      .catch(err => console.error(err));

    getLocations(this.props.brandId).then(locations =>
      this.setState({ locations })
    );
  }

  render() {
    return (
      <div className="App">
        <BrandMap google={this.state.google} locations={this.state.locations} />

        <div className="Footer">
          <MadeByHuggg />
        </div>
      </div>
    );
  }
}

App.propTypes = { brandId: PropTypes.string };

export default App;
