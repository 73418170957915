import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth, Session, setEnv } from '@huggg/back-bar';
import config from './Config';
import { queryParams } from './urlTools';

setEnv(config.env);
const session = new Session(config.clientKey, config.clientSecret);
new Auth(session);

/**
 * OK, I'm not putting in state management to check a URL for one param! It's over-engineering to
 * the max!
 *
 * But, on the other hand if this needs more than just brand; move it from the main App.
 *
 * This is an accetable hack. Extending it will not be.
 *
 * That is all.
 */
const getBrandId = () => queryParams(window.location.search)['brand'];

ReactDOM.render(
  <App brandId={getBrandId()} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
