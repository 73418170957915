import React from 'react';
import PropTypes from 'prop-types';

import styles from './loader.module.css';

const Loader = ({ fontSize = '24px' }) => {
  return (
    <span
      className={styles.loader}
      style={{ fontSize }}
      data-test-id="generic-loader"
    >
      <div />
      <div />
      <div />
      <div />
    </span>
  );
};

Loader.propTypes = {
  fontSize: PropTypes.string
};

export default Loader;
